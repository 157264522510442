<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs10 sm8 md8>
                <v-card class="elevation-4" id="SignInCard">
                        <h2 style="text-align:center; font-weight:600;">Welcome to Mailshipp</h2>
                    <transition name="fade-transition"><v-alert
                        border="left"
                        :color="ResponseClass"
                        dark
                        v-if="!!responseMsg"
                    >
                        {{responseMsg}}
                    </v-alert></transition>
                    <v-form ref="form" v-model="valid" lazy-validation v-on:keyup.enter="submit">
                    <v-card-text>
                        
                            <v-text-field prepend-icon="person" name="email" label="Email" type="email"
                                          v-model="email" :rules="emailRules" required color="#777BD2"> 
                            </v-text-field>
                            <v-text-field 
                                prepend-icon="lock"
                                name="password"
                                label="Password"
                                id="password"
                                required v-model="password"
                                :rules="passwordRules"
                                color="#777BD2"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                            >
                            </v-text-field>
                            <v-row no-gutters>
                                <v-col cols="12" sm="6">
                                    <v-checkbox
                                        v-model="rememberMeCheckbox"
                                        color="#777BD2"
                                        dense
                                    >
                                        <template v-slot:label>
                                            <div style="font-size: 14px">
                                                Remember Me
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="6" class="text-right">
                                    <router-link class="forgot-password-link" :to="{ name: 'Forgot Password'}">Forgot Password?</router-link>
                                </v-col>
                            </v-row>
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-btn dark type="submit" elevation="0" id="loginBtn" :disabled="signinActionBtnDisabled" @click="submit"><v-progress-circular
                            indeterminate
                            color="#fff"
                            v-if="signinActionInProgress"
                            class="progressLoader"
                        ></v-progress-circular><span v-if="!signinActionInProgress">Sign In</span></v-btn>
                    </v-card-actions>
                    </v-form>
                    <!--<p style="text-align:center; font-size:20px; margin-bottom:0px">OR</p>
                     <v-card-actions>
                        
                        <v-btn elevation="0" @click="googleSignin" id="signinGoogle" disabled>
                            <v-img src="@/assets/google-logo.png" max-width="15" max-height="15"></v-img><span>Sign-In with Google</span>
                        </v-btn>
                        
                    </v-card-actions> -->
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: 'SignIn',
    data() {
        return {
            email: '',
            password: '',
            valid: false,
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid'
            ],
            passwordRules: [
                v => !!v || 'Password is required'
            ],
            showPassword: false,
        };
    },
    methods: {
        submit() {
            this.$store.state.signinActionInProgress = true;
            this.$store.state.signinActionBtnDisabled = true;
            if (this.$refs.form.validate()) {
                
                this.$store.dispatch('userLogin', {
                    email: this.email,
                    password: this.password,
                    remember_me: this.rememberMeCheckbox
                });
                // Add 'aws-amplify' library into your application

                // Call Auth.signIn with user credentials
                // Auth.signIn(this.email, this.password)
                //     .then(user => console.log(user))
                //     .catch(err => console.log(err));
            }
            else{
                this.$store.state.signinActionInProgress = false;
                this.$store.state.signinActionBtnDisabled = false;
            }
        },
        /* googleSignin(){
            Auth.federatedSignIn({provider: 'Google'});
        } */
    },
    computed: {
        disabledLoginBtn(){
            return this.$store.state.disabledLogin;
        },
        responseMsg(){
            return this.$store.state.responseMsg;
        },
        ResponseClass(){
            return this.$store.state.ResponseClass;
        },
        signinActionInProgress(){
            return this.$store.state.signinActionInProgress;
        },
        signinActionBtnDisabled(){
            return this.$store.state.signinActionBtnDisabled;
        },
        rememberMeCheckbox:{
            set(value){
                this.$store.state.rememberMeCheckbox = value
            },
            get(){
                return this.$store.state.rememberMeCheckbox
            }
        }
    },
    watch: {
    },
    mounted(){
        if(this.$store.state.user != null){
            this.$router.push({ path: '/' });
        }
    }
};
</script>
<style scoped>
button#loginBtn{
    margin: auto;
    padding: 20px 60px;
    background-color: #777BD2 !important;
}
#signinGoogle{
    margin: auto;
    padding: 20px 25px;
}
#signinGoogle .v-image{
    margin-right: 5px;
}
button#signinGoogle, button#loginBtn{
    min-width: 240px;
}
.forgot-password-link{
    line-height: 2.5;
}
.v-application a{
    color:#777BD2 !important
}
.v-input--checkbox{
    margin-top: 0px;
}
@media screen and (min-width: 960px){
    #SignInCard{
        padding: 50px 90px 60px;
    }  
}
@media screen and (max-width: 959px) and (min-width: 600px){
    #SignInCard{
        padding: 15px 30px 30px;
    }  
}
@media screen and (max-width: 600px){
    #SignInCard{
        padding: 5px 30px 20px;
    }  
}

</style>